import DownloadIcon from "assets/images/download-icon.svg";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import ScoreForm from "components/ScoreForm";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Download_Data,
  LEARNER_LIST,
  Learners_Overview,
  SCORE_FORM,
} from "resources/constants/strings";
import { useTrackedState } from "store/store";
import {
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const DOWNLOAD_ICON_STYLE = {
  width: "12px",
  height: "12px",
  marginRight: "16px",
};

export default function Title({ exportPdf }) {
  const [css, theme] = useStyletron();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const role = localStorage.getItem("role");

  const handleRedirect = () => {
    history.push("/learner-list");
  };
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gridGap="16px"
      marginBottom={["16px", "16px", "20px"]}
      flexWrap="wrap"
    >
      <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="title">
        {Learners_Overview}
      </Block>
      <Block
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gridGap="16px"
        marginBottom={["16px", "16px", "20px"]}
        flexWrap="wrap"
      >
        <Block>
          {localStorage.getItem("role") === "MENTOR" ||
            (localStorage.getItem("viewAs") === "MENTOR" && (
              <Button
                onClick={handleRedirect}
                overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
                data-testid="download-button"
              >
                <span>{LEARNER_LIST}</span>
              </Button>
            ))}
        </Block>
        <Block display={"contents"}>
          {role === "ADMIN" && (
            <Button
              onClick={handleModal}
              overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
              data-testid="download-button"
            >
              <span>{SCORE_FORM}</span>
            </Button>
          )}
          <Button
            onClick={exportPdf}
            overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
            data-testid="download-button"
          >
            <img
              src={DownloadIcon}
              alt="Download"
              className={css(DOWNLOAD_ICON_STYLE)}
            />
            <span>{Download_Data}</span>
          </Button>
        </Block>
      </Block>
      {isModalOpen && <ScoreForm handleModal={handleModal} />}
    </Block>
  );
}
