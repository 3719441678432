export const COMMON_RADIUS = (value = "4px") => ({
  borderTopRightRadius: value,
  borderBottomRightRadius: value,
  borderTopLeftRadius: value,
  borderBottomLeftRadius: value,
  
})

export const COMMON_BORDER_COLOR = (value) => ({
  borderTopColor: value,
  borderLeftColor: value,
  borderRightColor: value,
  borderBottomColor: value,
})

export const COMMON_BORDER_WIDTH = (value = "1px") => ({
  borderTopWidth: value,
  borderRightWidth: value,
  borderLeftWidth: value,
  borderBottomWidth: value,
})

export const COMMON_BORDER_STYLE = (value = "solid") => ({
  borderTopStyle: value,
  borderRightStyle: value,
  borderLeftStyle: value,
  borderBottomStyle: value,
})

export const COMMON_CARD_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  height: 'initial',

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});

export const COMMON_PADDING = (value = '0px') => ({
  paddingTop: value,
  paddingRight: value,
  paddingBottom: value,
  paddingLeft: value,
})

export const MODAL_BODY_STYLE = (theme) => ({
  margin: "24px !important",
  [theme.mediaQuery.small]: {
    margin: "16px !important",
  },
  [theme.mediaQuery.medium]: {
    margin: "24px !important",
  },
});

export const TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Bold",
  fontSize: "24px",
  lineHeight: "33px",
  marginBottom: "20px",
  [theme.mediaQuery.small]: {
    fontSize: "18px",
  },
  [theme.mediaQuery.large]: {
    fontSize: "24px",
  },
});

const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
export const HEADING_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontSize: "24px",
  color: theme.RAColors?.black,
});

export const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
    fontFamily: "Manrope",
  }),
  Root: ({ $theme, $error }) => ({
    maxWidth: "100%",
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_WIDTH(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    [$theme.mediaQuery.small]: {
      width: "auto",
    },
    [$theme.mediaQuery.medium]: {
      width: "400px",
    },
  }),
};

export const SAVE_BTN_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  width: "max-content",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "10px",
  paddingTop: "10px",
  fontFamily: "Manrope-Bold",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: "700",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "22px",
  marginTop: 0,
  ...COMMON_RADIUS("8px"),
  marginBottom: "0px",
  backgroundColor: $theme.RAColors?.blue700,
  color: "#FFFFFF",
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  [$theme.mediaQuery.small]: {
    width: "100%",
    marginBottom: "12px",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
    marginBottom: "0px",
  },
});

export const CANCEL_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  marginLeft: "12px",
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
  display: "flex",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

export const DOWNLOAD_ICON_STYLE = {
  width: "12px",
  height: "12px",
  marginRight: "16px",
};

export const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  MessageCell: { minWidth: "8%", width: "25%" },
  TimeCell: { width: "25%" },
  IdCell: { width: "25%" },
  ViewCell: { width: "5%" },
};

