import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Input } from "baseui/input";
import { Modal, ModalBody } from "baseui/modal";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { useState } from "react";
import { fetchCanvasStudentData } from "services/requests/cohort_student_data";
import { useTrackedState } from "store/store";
import {
  INPUT_OVERRIDE_STYLE,
  MODAL_BODY_STYLE,
  SAVE_BTN_STYLE,
  TABLE_OVERRIDE_STYLE,
} from "utils/style";

function ScoreForm({ handleModal }) {
  const [css, theme] = useStyletron();

  const state = useTrackedState();

  const [inputItem, setInputItem] = useState({
    email: "",
    quest_name: "",
    canvasTable: [],
    studentNodeTable: [],
  });
  const [disable, setDisable] = useState(false);
  const onSubmitFormData = async () => {
    setDisable(true);
    const { email, quest_name } = inputItem;
    await fetchCanvasStudentData(email, "LEARNER")
      .then(function (response) {
        if (response.status === 200) {
          const res = JSON.parse(
            JSON.stringify(
              state?.learners?.filter((obj) => obj.email_id === email)
            )
          );

          setInputItem((prev) => {
            const updated = {
              ...prev,
              canvasTable: response?.data[0]?.cohorts_data
                ? getScoreAndPathForQuest(
                    response?.data[0]?.cohorts_data,
                    quest_name
                  )
                : [],
              studentNodeTable: extractAssignmentDetails(
                res[0]?.["learning_path"],
                quest_name
              ),
            };
            console.log("Updated State:", updated);
            return updated;
          });
          setDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function getScoreAndPathForQuest(cohortsData, questName) {
    const result = []; // Array to store all matching score and path pairs

    for (const cohort of cohortsData) {
      if (cohort.course) {
        for (const course of cohort.course) {
          if (course.course_name === questName) {
            if (course.student_data) {
              for (const student of course.student_data) {
                if (student.lessons) {
                  for (const lesson of student.lessons) {
                    if (lesson.room) {
                      for (const room of lesson.room) {
                        if (room.assignment_submitted_detail) {
                          for (const detail of room.assignment_submitted_detail) {
                            result.push({
                              score: detail.score,
                              path: detail.path,
                            });
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return result.length > 0 ? result : []; // Return result array or null if no matches
  }

  const extractAssignmentDetails = (learningPath, targetCourseName) => {
    const result = [];

    const traverse = (node) => {
      if (!node) return;

      // Check if the current node has course_list with the matching course_name
      if (node.course_list) {
        node?.course_list?.forEach((course) => {
          if (course?.course_name === targetCourseName && course?.lesson_list) {
            course?.lesson_list?.forEach((lesson) => {
              if (lesson.assignment_list) {
                lesson?.assignment_list?.forEach((assignment) => {
                  result.push({
                    assignment_name: assignment?.assignment_name,
                    progress_actual_cnt:
                      assignment?.assignment_progress?.progress_actual_cnt,
                  });
                });
              }
            });
          }
        });
      }

      // Traverse deeper into other parts of the structure
      Object.values(node)?.forEach((value) => {
        if (Array.isArray(value)) {
          value?.forEach(traverse);
        }
      });
    };

    traverse(learningPath);
    return result ? result : [];
  };

  return (
    <Modal
      onClose={handleModal}
      closeable
      isOpen={true}
      animate
      autoFocus
      overrides={{
        Dialog: {
          style: {
            width: "80vw",
            height: "80vh",
            display: "flex",
            flexDirection: "column",
          },
        },
      }}
    >
      <ModalBody className={css(MODAL_BODY_STYLE(theme))}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitFormData();
          }}
        >
          <Block
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            gridGap="16px"
            marginBottom={["16px", "16px", "20px"]}
          >
            <Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                inputProps={{
                  "data-testid": "email",
                }}
                name="email"
                data-testid="email"
                minLength={2}
                aria-label="cost-input"
                required={true}
                onChange={(e) =>
                  setInputItem({ ...inputItem, email: e.target.value })
                }
                placeholder="Email Id"
              />
            </Block>
            <Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                inputProps={{
                  "data-testid": "quest_name",
                }}
                name="quest_name"
                data-testid="quest_name"
                minLength={2}
                aria-label="cost-input"
                required={true}
                onChange={(e) =>
                  setInputItem({ ...inputItem, quest_name: e.target.value })
                }
                placeholder="quest_name"
              />
            </Block>
            <Button
              type="submit"
              data-testid="save"
              overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
              disabled={disable}
            >
              Submit
            </Button>
          </Block>
        </form>
        <h1 style={{ padding: "2rem" }}>Response Data from Canvas End point</h1>
        {inputItem?.canvasTable?.length > 0 && (
          <TableBuilder
            data={inputItem?.canvasTable}
            //   isLoading={isFetching || false}
            emptyMessage="No Data"
            overrides={{ Root: { style: TABLE_OVERRIDE_STYLE.Root } }}
          >
            <TableBuilderColumn
              header="Name"
              overrides={{
                TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
              }}
            >
              {(row) => (
                <div data-testid="row-message-linkforce">{row.path}</div>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn
              header="Score"
              overrides={{
                TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
              }}
            >
              {(row) => (
                <div data-testid="row-message-linkforce">{row.score}</div>
              )}
            </TableBuilderColumn>
          </TableBuilder>
        )}
        <h1 style={{ padding: "2rem" }}>
          Response Data from Student Node End point
        </h1>
        <div style={{ height: "15rem", overflowY: "auto" }}>
          {inputItem?.studentNodeTable?.length > 0 && (
            <TableBuilder
              data={inputItem?.studentNodeTable}
              emptyMessage="No Data"
              overrides={{
                Root: {
                  style: { ...TABLE_OVERRIDE_STYLE.Root },
                },
              }}
            >
              <TableBuilderColumn
                header="Name"
                overrides={{
                  TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                  TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                }}
              >
                {(row) => (
                  <div data-testid="row-message-linkforce">
                    {row.assignment_name}
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="Score"
                overrides={{
                  TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                  TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                }}
              >
                {(row) => (
                  <div data-testid="row-message-linkforce">
                    {row?.progress_actual_cnt}
                  </div>
                )}
              </TableBuilderColumn>
            </TableBuilder>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ScoreForm;
